<template>
  <div class="container">
    <div class="headerBox flex">
      <div
        class="flex flex-center"
        @click="headerChange('1')"
        :class="{ active: headerActive === '1' }"
      >
        {{ $t("market.openHouse") }}
      </div>
      <div
        class="flex flex-center"
        @click="headerChange('3')"
        :class="{ active: headerActive === '3' }"
      >
        {{ $t("market.hasNum") }}
      </div>
    </div>
    <div class="botHeader flex jus-bet">
      <div class="headerLet flex">
        <span
          @click="headerCenterChange('1')"
          :class="{ active: nowActive === '1' }"
          >{{ $t("market.commission") }}</span
        >
        <span
          @click="headerCenterChange('0')"
          :class="{ active: nowActive === '0' }"
          >{{ $t("market.fixedPrice") }}</span
        >
      </div>
      <span class="active" @click="jumpPage('/assetsRecord')">{{
        $t("market.wholeAll")
      }}</span>
    </div>
    <div class="contentBox">
      <div class="contentContainer" v-if="list.length > 0">
        <div class="nameBox">
          <div class="nameLeft">
            <span>{{ list[0].symbol }}</span>
            <el-tag
              type="warning"
              size="mini"
              style="margin-left: 5px; font-size: 14px"
              >{{ list[0].multiple }}X</el-tag
            >
          </div>
          <el-button
            type="primary"
            @click="closeBank"
            size="mini"
            v-if="list[0].status == 1"
            >{{ $t("order.closingText") }}</el-button
          >
        </div>
        <div class="statusBox">
          <el-tag
            :type="list[0].type == 1 ? 'success' : 'danger'"
            size="mini"
            style="margin-left: 5px"
            >{{
              list[0].type == 1 ? $t("market.doMore") : $t("market.doLess")
            }}</el-tag
          >
          <el-tag size="mini" style="margin-left: 5px">{{
            list[0].trans_type == 1
              ? $t("market.commissionText")
              : $t("market.fixedText")
          }}</el-tag>
          <span class="timeSpan">{{ list[0].time }}</span>
        </div>
        <div class="lineItem">
          <div class="lineRaw">
            <span>{{ $t("market.openingNum") }}</span>
            <span>{{ list[0].number }}</span>
          </div>
          <div class="lineRaw">
            <span>{{ $t("market.transactionPrice") }}</span>
            <span>{{ toSelfFixed(list[0].price, 2) }}</span>
          </div>
          <div class="lineRaw">
            <span>{{ $t("market.newPrice") }}</span>
            <span>{{ toSelfFixed(list[0].update_price, 2) }}</span>
          </div>
        </div>
        <div class="lineItem">
          <div class="lineRaw">
            <span>{{ $t("market.guaranteePrice") }}</span>
            <span>{{ toSelfFixed(list[0].caution_money, 2) }}</span>
          </div>
          <div class="lineRaw">
            <span>{{ $t("market.serviceCharge") }}</span>
            <span>{{ toSelfFixed(list[0].trade_fee, 2) }}</span>
          </div>
          <div class="lineRaw">
            <span>{{ $t("market.profitLoss") }}</span>
            <span>{{ toSelfFixed(list[0].profits, 2) }}</span>
          </div>
        </div>
      </div>
      <el-empty v-if="list.length == 0" :image-size="100"></el-empty>
    </div>
  </div>
</template>
<script setup>
import { Message, MessageBox } from "element-ui";
import { toSelfFixed } from "@/utils/common";
import { useRouter } from "@/utils/vueApi";
import { get_wh_trade, get_my_trade } from "@/api/market";
import { ref, watch, getCurrentInstance } from "vue";
import { useLang } from "@/hooks/useLang";
import { numberClose, whclose } from "@/api/market";
const { proxy } = getCurrentInstance();
const { lang } = useLang();
const props = defineProps({
  infoId: {},
  // 1-数字货币 5-贵金属 6-外汇
  tabType: {
    type: String,
  },
});

const router = useRouter();
// 仓
const headerActive = ref("1");
const headerChange = (e) => {
  headerActive.value = e;
  initData();
};
// 市场委托 / 限价委托
const nowActive = ref("1");
const headerCenterChange = (e) => {
  nowActive.value = e;
  initData();
};
const list = ref([]);
const jumpPage = (url) => {
  router.push(url);
};
const initData = async () => {
  list.value = [];
  if (props.tabType == "1") {
    // 数字
    const { message } = await get_my_trade({
      currency_id: props.infoId,
      legal_id: "3",
      limit: "999999",
      lang: lang.value,
      status: headerActive.value,
      trans_type: nowActive.value,
    });
    list.value = message.message.data;
  } else {
    // 外汇
    const { message } = await get_wh_trade({
      limit: "999999",
      pid: props.infoId,
      lang: lang.value,
      status: headerActive.value,
      type: props.tabType,
      trans_type: nowActive.value,
    });
    list.value = message.data;
  }
};
// 平仓
const closeBank = () => {
  MessageBox.confirm(proxy.$t("tips.sureClosing"), proxy.$t("tips.tip"), {
    showClose: true,
    confirmButtonText: proxy.$t("tips.sureText"),
    cancelButtonText: proxy.$t("tips.cancelText"),
    type: "warning",
  }).then(async () => {
    if (props.tabType == "1") {
      // 数字
      const { type, message } = await numberClose({
        id: list.value[0].id,
        lang: lang.value,
      });
      if (type == "ok") {
        Message.success(proxy.$t(message));
      } else {
        Message.error(proxy.$t(message));
      }
    } else {
      // 贵金属
      const { type, message } = await whclose({
        id: list.value[0].id,
        lang: lang.value,
      });
      if (type == "ok") {
        Message.success(proxy.$t(message));
      } else {
        Message.error(proxy.$t(message));
      }
    }
    initData();
  });
};
watch(
  () => props.infoId,
  (id) => {
    if (id) {
      initData();
    }
  }
);
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  box-sizing: border-box;
  height: 315px;
  display: flex;
  flex-direction: column;
  .headerBox {
    border-bottom: 1px solid $market-list-hover;
    justify-content: space-around;
    div {
      cursor: pointer;
      padding-bottom: 5px;
      font-weight: bold;
      &.active {
        color: #5670f6;
        border-bottom: 2px solid #5670f6;
      }
    }
  }
  .botHeader {
    margin-top: 10px;
    .headerLet {
      span {
        margin-right: 20px;
      }
    }
    span {
      font-size: 14px;
      cursor: pointer;
      padding: 5px;
      border-radius: 5px;
      &.active {
        border-bottom: 2px solid;
        background: #5670f6;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .contentBox {
    overflow-y: auto;
    flex: 1;
    margin-top: 10px;
    font-size: 14px;
    .contentContainer {
      .nameBox {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
      }
      .statusBox {
        font-size: 16px;
        margin-top: 10px;
        .timeSpan {
          font-size: 14px;
          margin-left: 5px;
        }
      }
      .lineItem {
        margin-top: 10px;
        display: flex;
        padding: 0 10px;
        box-sizing: border-box;
        .lineRaw {
          flex: 1;
          align-items: center;
          display: flex;
          flex-direction: column;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
