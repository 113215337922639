<template>
  <div class="leftBotContainer">
    <div class="btnBox flex">
      <div class="leftBtn flex-1 flex">
        <div
          class="btnItem"
          :class="{ redBg: btnActive == '1' }"
          @click="handClick('1')"
        >
          {{ $t("market.doMore") }}
        </div>
        <div
          class="btnItem"
          :class="{ greenBg: btnActive == '2' }"
          @click="handClick('2')"
        >
          {{ $t("market.doLess") }}
        </div>
      </div>
    </div>
    <div class="numBox flex">
      <el-select
        v-model="form.status"
        :placeholder="$t('tips.pleaseSelect')"
        style="width: 100%; margin-right: 10px"
      >
        <el-option
          v-for="item in buyTypeList"
          :key="item.value"
          size="mini"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form.multiple"
        :placeholder="$t('tips.pleaseSelect')"
        style="width: 100%"
      >
        <el-option
          v-for="item in muitList"
          :key="item.value"
          size="mini"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="numBox">
      <el-input
        v-model="form.target_price"
        style="width: 100%"
        size="small"
        @input="priceChange"
        :readonly="form.status == '1'"
        :placeholder="$t('market.volume')"
      ></el-input>
    </div>
    <div class="numBox flex ali-cen">
      <el-input-number
        v-model="form.share"
        style="width: 100%"
        size="small"
        controls-position="right"
        @change="numberChange"
        :placeholder="$t('market.volume')"
      ></el-input-number>
      <span>{{ $t("market.font") }}</span>
    </div>
    <!-- 止盈 -->
    <div class="numBox flex ali-cen">
      <span>{{ $t("market.takeProfit") }}</span>
      <el-input-number
        v-model="form.zy"
        style="flex: 1"
        size="small"
        controls-position="right"
        :placeholder="$t('market.profitPlace')"
      ></el-input-number>
    </div>
    <!-- 止损 -->
    <div class="numBox flex ali-cen">
      <span>{{ $t("market.stopLoss") }}</span>
      <el-input-number
        v-model="form.zs"
        style="flex: 1"
        size="small"
        controls-position="right"
        :placeholder="$t('market.lossPlace')"
      ></el-input-number>
    </div>
    <div class="numTips">
      <template v-if="tabType == '1'">
        <!-- 每手价格 -->
        <p>
          {{ $t("market.fontMoney")
          }}{{
            info.lever_share_num ? toSelfFixed(info.lever_share_num, 0) : "0.00"
          }}
          USD
        </p>
        <!-- 保证金 -->
        <p>
          {{ $t("market.bondMoney")
          }}{{
            form.share
              ? (form.share * info.lever_share_num) / form.multiple
              : "0.00"
          }}
          USD
        </p>
        <!-- 手续费 -->
        <p>
          {{ $t("market.serviceCharge")
          }}{{ form.share ? form.share * info.lever_trade_fee : "0.00" }} （{{
            info.lever_trade_fee || "0.00"
          }}/{{ $t("market.font") }}）
        </p>
      </template>
      <template v-else>
        <!-- 每手价格 -->
        <p>
          {{ $t("market.fontMoney") }}{{ info.hand_price || "0.00" }}
          USD
        </p>
        <!-- 保证金 -->
        <p>
          {{ $t("market.bondMoney")
          }}{{
            form.share ? (form.share * info.hand_price) / form.multiple : "0.00"
          }}
          USD
        </p>
        <!-- 手续费 -->
        <p>
          {{ $t("market.serviceCharge")
          }}{{ form.share ? form.share * info.level_fee : "0.00" }} （{{
            info.level_fee || "0.00"
          }}/{{ $t("market.font") }}）
        </p>
      </template>
    </div>
    <div
      class="footerBtn flex flex-center"
      @click="submit"
      :class="{ redBg: btnActive == '1', greenBg: btnActive == '2' }"
    >
      {{ btnActive == "1" ? $t("market.buyMoreBtn") : $t("market.buyLessBtn") }}
    </div>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance, watch } from "vue";
import { useOption } from "@/hooks/useOption";
import { toSelfFixed } from "@/utils/common";
import { Message } from "element-ui";
import { numberSubmit, whsubmit } from "@/api/market";
import { useLang } from "@/hooks/useLang";
const { lang } = useLang();
const { buyTypeList, muitList } = useOption();
const props = defineProps({
  info: {
    type: Object,
    default: () => {
      return {};
    },
  },
  // 1-数字货币 5-贵金属 6-外汇
  tabType: {
    type: String,
  },
});
// 是否编辑过
const hasEdit = ref(false);
watch(
  () => props.info,
  (val, oldVal) => {
    if (props.tabType == "1") {
      //  form.value.status == 0 限价 改过后不让改
      if(val.id != oldVal.id) {
        hasEdit.value = false;
      }
      // 如果在限价状态下手动改了，不更新数据
      if(form.value.status == 0 && hasEdit.value) {
        return 
      }
      form.value.target_price = val.now_price;
    } else {
      if(val.pid != oldVal.pid) {
        hasEdit.value = false;
      }
      // 如果在限价状态下手动改了，不更新数据
      if(form.value.status == 0 && hasEdit.value) {
        return 
      }
      form.value.target_price = val.productdata ? val.productdata.price : 0;
    }
  },
  {
    deep: true,
  }
);

const priceChange = (val) => {
  // console.log('改变')
  hasEdit.value = true;
}
const vm = getCurrentInstance();
const btnActive = ref("1");
const handClick = (val) => {
  btnActive.value = val;
};
const form = ref({
  status: "1", // 委托方式   0是限价 1是市价
  multiple: "5", // 杠杆倍数
  target_price: null, // 限价获取最新价，也可以去改这个。如果是市价。不能修改
  share: 1, // 数量
  zy: null, // 止盈
  zs: null, // '止损',
});
// 数量改变
const numberChange = () => {};
const submit = async () => {
  if (!form.value.share) {
    Message.error(vm.proxy.$t("market.volumePlac"));
    return false;
  }
  // else if (!form.value.zy) {
  //   Message.error(vm.proxy.$t("market.profitPlace"));
  //   return false;
  // } else if (!form.value.zs) {
  //   Message.error(vm.proxy.$t("market.lossPlace"));
  //   return false;
  // }
  const postData = {
    status: form.value.status,
    share: form.value.share,
    multiple: form.value.multiple,
    legal_id: props.info.legal_id,
    type: btnActive.value,
    target_price: form.value.target_price,
    zy: form.value.zy,
    zs: form.value.zs,
    lang: lang.value,
  };
  if (props.tabType == "1") {
    postData.currency_id = props.info.currency_id;
    const { type, message } = await numberSubmit(postData);
    if (type == "ok") {
      Message.success(vm.proxy.$t(message));
    } else {
      Message.error(vm.proxy.$t(message));
    }
  } else {
    postData.pid = props.info.pid;
    const { type, message } = await whsubmit(postData);
    if (type == "ok") {
      Message.success(vm.proxy.$t(message));
    } else {
      Message.error(vm.proxy.$t(message));
    }
  }
};
</script>

<style lang="scss" scoped>
.leftBotContainer {
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  .btnBox {
    font-weight: bold;
    .leftBtn {
      .btnItem {
        width: calc(50% - 5px);
        border: none;
        font-size: 14px;
        background-color: $theme-color;
        border: 1px solid $market-list-hover;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
          background-color: #cd4e65;
          color: #fff;
        }
        &:last-child {
          margin-left: 10px;
          &:hover {
            background-color: #1cad90;
          }
        }
      }
    }
  }
  .tipP {
    margin: 5px 0;
    font-size: 13px;
  }
  .numBox {
    width: 100%;
    margin-top: 10px;
    span {
      font-size: 14px;
      margin: 0 5px;
    }
  }
  .numTips {
    margin-top: 10px;
    font-size: 13px;
    p {
      margin: 5px 0;
    }
  }
  .footerBtn {
    width: 100%;
    cursor: pointer;
    height: 38px;
    margin-top: 15px;
    font-weight: bold;
    border-radius: 5px;
  }
}
</style>
