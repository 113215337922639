<template>
  <div class="marketDetail flex">
    <div class="leftInfo flex-1 flex-col width10">
      <div class="leftTop shadow-box bg-box">
        <leftTop
          @getDetailInfo="getDetailInfo"
          @reset="resetLeft"
          :metalList="metalList"
          :digitalCurrency="digitalCurrency"
          ref="leftTopRef"
        ></leftTop>
      </div>
      <div class="leftBot shadow-box bg-box" style="margin-top: 10px">
        <infoData :infoId="infoId" :tabType="tabType"></infoData>
      </div>
    </div>
    <div class="midChar flex-2 width10">
      <div class="midTop shadow-box bg-box">
        <midTop :info="mideTopData" :tabType="tabType"></midTop>
      </div>
      <!-- <div class="midCenter shadow-box bg-box" style="margin-top: 10px">
        <noticeBar></noticeBar>
      </div> -->
      <div class="midBot flex-1 shadow-box bg-box" style="margin-top: 10px">
        <lineChar :charData="charData" @changeHeader="changeHeader"></lineChar>
      </div>
    </div>
    <div class="leftInfo flex-1 width10">
      <div
        class="shadow-box bg-box"
        style="margin-bottom: 10px"
        v-if="tabType == '1'"
      >
        <rightTop :buyList="buyList" :sellList="sellList"></rightTop>
      </div>
      <div class="leftBot shadow-box bg-box">
        <rightBot :info="mideTopData" :tabType="tabType"></rightBot>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  onMounted,
  getCurrentInstance,
  watch,
  onUnmounted,
  computed,
} from "vue";
import infoData from "@/components/market/marketContract/infoData.vue";
import leftTop from "@/components/market/marketDetail/leftTop.vue";
import rightBot from "@/components/market/marketContract/rightBot.vue";
// import midTop from "@/components/market/marketContract/midTop.vue";
import midTop from "@/components/market/marketDetail/midTop.vue";
// import noticeBar from "@/components/market/marketContract/noticeBar.vue";
import rightTop from "@/components/market/marketContract/rightTop.vue";
import lineChar from "@/components/market/marketDetail/lineChar.vue";
import Socketio from "@/utils/socket/socket.io";
import { getToken } from "@/utils/auth";
import {
  get_new_timeshar,
  getWhkChar,
  getQuotation_new,
  getWhlist,
} from "@/api/market";
const vm = getCurrentInstance();
const routeId = ref(null);
const isRoute = ref(false);
const tabType = ref("1"); // 1-数字货币 5-贵金属 6-外汇
const mideTopData = ref({});
const infoId = computed(() => {
  if (tabType.value == "1") {
    return mideTopData.value.currency_id;
  } else {
    return mideTopData.value.pid;
  }
});
// 监听传参
watch(
  () => vm.proxy.$route,
  (newVal) => {
    routeId.value = newVal.query.id;
    tabType.value = newVal.query.type ? newVal.query.type : "1";
    if (routeId.value) {
      isRoute.value = true;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
// 数字货币
const digitalCurrency = ref({
  quotation: [],
});
// 贵金属 、外汇
const metalList = ref([]);
// 获取数据
const initData = async () => {
  if (tabType.value == "1") {
    const { message } = await getQuotation_new();
    digitalCurrency.value = message[0];
    mideTopData.value = digitalCurrency.value.quotation[0];
    leftTopRef.value.setActiveList(digitalCurrency.value.quotation[0].id);
    // 设置默认id
  } else {
    const { message } = await getWhlist({
      cid: tabType.value,
    });
    metalList.value = message;
    mideTopData.value = metalList.value[0];
    leftTopRef.value.setActiveList(metalList.value[0].productdata.id);
    // 设置默认id
  }
  // 是否为第一次跳转进来
  if (isRoute.value) {
    getRouteSetData();
  }
  getDetailInfo(tabType.value, mideTopData.value);
};
// 通过路由参数设置数据
const getRouteSetData = () => {
  if (!routeId.value) {
    return false;
  }
  if (tabType.value == "1") {
    digitalCurrency.value.quotation.forEach((item) => {
      if (item.id == routeId.value) {
        mideTopData.value = item;
        leftTopRef.value.setActiveList(item.id);
      }
    });
  } else {
    metalList.value.forEach((item) => {
      if (item.productdata.id == routeId.value) {
        mideTopData.value = item;
        leftTopRef.value.setActiveList(item.productdata.id);
        leftTopRef.value.setActiveName(tabType.value);
      }
    });
  }
};
const leftTopRef = ref(null);
// 重置左上角
const resetLeft = (val) => {
  digitalCurrency.value = {
    quotation: [],
  };
  metalList.value = [];
  tabType.value = val;
  mideTopData.value = {};
  // 切换tab后将其变成false，防止重置成route
  isRoute.value = false;
  initData();
};
// 点击类型获取详细数据 - 选中数据切换
const getDetailInfo = (type, obj) => {
  tabType.value = type;
  mideTopData.value = obj;
  getIntervalCharData();
};
let timer;
const getIntervalCharData = async () => {
  if (timer) {
    clearInterval(timer);
  }
  getCharData();
  timer = setInterval(() => {
    getCharData();
  }, 5000);
};
const buyList = ref([]);
const sellList = ref([]);
// 长连接
const handicap = () => {
  var tokens = getToken();
  var socket = Socketio(vm.proxy.$socket_api);
  socket.emit("login", tokens);
  socket.on("market_depth", (msg) => {
    if (
      msg.currency_id == mideTopData.value.currency_id &&
      msg.legal_id == mideTopData.value.legal_id
    ) {
      buyList.value = msg.bids.slice(0, 6);
      sellList.value = msg.asks.slice(-6);
    }
  });
  // 中间顶部详情数据
  socket.on("daymarket", (msg) => {
    if (
      msg.currency_id == mideTopData.value.currency_id &&
      msg.legal_id == mideTopData.value.legal_id
    ) {
      mideTopData.value = { ...mideTopData.value, ...msg };
    }
  });
};
// 图表数据
const charData = ref([]);
const charTypeObj = ref({});
// 图表头部切换
const changeHeader = (e) => {
  charTypeObj.value = e;
  getIntervalCharData();
};
// 获取图表数据
const getCharData = async () => {
  charData.value = [];
  if (tabType.value == "1") {
    if (mideTopData.value.currency_id) {
      const nowTimes = Number(new Date().getTime() / 1000).toFixed(0);
      const from = nowTimes - 1500 * 60;
      const { data } = await get_new_timeshar({
        from: from,
        to: nowTimes,
        symbol: `${mideTopData.value.currency_name}/${mideTopData.value.legal_name}`,
        period: charTypeObj.value.type ? charTypeObj.value.type : "1min",
      });
      data.forEach((e) => {
        e.timestamp = e.time;
      });
      charData.value = data;
    }
  } else {
    if (mideTopData.value.pid) {
      const { message } = await getWhkChar({
        pid: mideTopData.value.pid,
        interval: charTypeObj.value.value ? charTypeObj.value.value : "1",
        num: 500,
      });
      const arr = [];
      message.items.forEach((e) => {
        arr.push({
          timestamp: e[0] * 1000,
          open: Number(e[1]),
          high: Number(e[2]),
          low: Number(e[3]),
          close: Number(e[4]),
        });
      });
      charData.value = arr;
      mideTopData.value.productdata.price = message.topdata.now;
      mideTopData.value.productdata.open = message.topdata.open;
      mideTopData.value.productdata.high = message.topdata.highest;
      mideTopData.value.productdata.close = message.topdata.close;
    }
  }
};

onMounted(() => {
  initData();
  handicap();
});
onUnmounted(() => {
  if (timer) {
    clearInterval(timer);
  }
});
</script>
<style lang="scss" scoped>
.marketDetail {
  margin: 5px 20px;
  .bg-box {
    border-radius: 5px;
  }
  .width10 {
    width: 10px;
  }
  .leftInfo {
    padding: 5px;
    box-sizing: border-box;
  }
  .leftTop {
    padding: 5px;
    height: 315px;
    box-sizing: border-box;
  }
  .midChar {
    margin: 5px 20px;
  }
  .rightList {
    padding: 5px;
    margin-top: 5px;
    height: fit-content;
    box-sizing: border-box;
  }
}
</style>
